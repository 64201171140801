import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useIdentityContext } from "react-netlify-identity-widget";

function PrivateRoute(props) {
  const { isLoggedIn, user } = useIdentityContext();
  const { component: Component, location, rolesAllowed, ...rest } = props;

  React.useEffect(() => {
    if (!isLoggedIn && location.pathname !== `/app/login`) {
      // If the user is not logged in, redirect to the login page.
      navigate(`/app/login`);
    }
  }, [isLoggedIn, location]);
  // return isLoggedIn ? <Component {...rest} /> : null;
  return isLoggedIn &&
    rolesAllowed &&
    rolesAllowed.some((r) => user?.app_metadata?.roles?.indexOf(r) >= 0) ? (
    <Component {...rest} />
  ) : null;
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.node.isRequired,
  location: PropTypes.node.isRequired,
  rolesAllowed: PropTypes.node.isRequired,
};
