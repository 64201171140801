import React from "react";
import { useIdentityContext } from "react-netlify-identity-widget";
import { Container, Row } from "react-bootstrap";

function Main() {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState("");

  const { user, isLoggedIn, logoutUser } = useIdentityContext();
  const message = isLoggedIn
    ? `Hello ${user.user_metadata && user.user_metadata.full_name}`
    : "You are not logged in";

  return (
    <Container style={{ paddingTop: "30px" }}>
      <h1>{message}</h1>
      <ul>
        <li>Name: {user.user_metadata && user.user_metadata.full_name}</li>
        <li>E-mail: {user.email}</li>
      </ul>
    </Container>
  );
}

export default Main;
