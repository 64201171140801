import React from "react";
import { useIdentityContext } from "react-netlify-identity-widget";
import { Container, Row } from "react-bootstrap";

const Partner = () => {
  const { user } = useIdentityContext();
  return (
    <Container style={{ paddingTop: "30px" }}>
      <h1>Partner page</h1>
    </Container>
  );
};

export default Partner;
