import React from "react";
import PropTypes from "prop-types";
import { Router } from "@reach/router";
import Layout from "../components/Layout";
import NavBar from "./components/NavBar";
import Partner from "./partner";
import Investor from "./investor";
import Main from "./main";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./login";

const App = () => {
  return (
    <>
      <NavBar />
      <Router>
        <PrivateRoute path="/app/partner" component={Partner} rolesAllowed={["Partner"]} />
        <PrivateRoute path="/app/investor" component={Investor} rolesAllowed={["Investor"]} />
        <PublicRoute path="/app">
          <PrivateRoute path="/" component={Main} rolesAllowed={["User"]} />
          <Login path="/login" />
        </PublicRoute>
      </Router>
    </>
  );
};
function PublicRoute({ children }) {
  return <div>{children}</div>;
}

export default App;

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
