import React from "react";
import { Link, navigate } from "gatsby";
import { useIdentityContext } from "react-netlify-identity-widget";
import { Container, Row, Navbar, Nav } from "react-bootstrap";
import "./NavBar.scss";
import Logo from "components/Logo";

const NavBar = () => {
  const { user, isLoggedIn, logoutUser } = useIdentityContext();
  const message = isLoggedIn
    ? `Hello, ${user.user_metadata && user.user_metadata.full_name}`
    : "You are not logged in";

  console.log("USER", user);
  return (
    <Navbar className="app-nav">
      <Container>
        <Navbar.Brand href="/">
          <Logo />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/app">Profile</Nav.Link>

            {isLoggedIn && user.app_metadata.roles.includes("Partner") && (
              <Nav.Link href="/app/partner">Partner</Nav.Link>
            )}
            {isLoggedIn && user.app_metadata.roles.includes("Investor") && (
              <Nav.Link href="/app/investor">Investor</Nav.Link>
            )}

            {isLoggedIn ? (
              <Nav.Link
                href="/"
                onClick={async (event) => {
                  event.preventDefault();
                  await logoutUser();
                  navigate(`/app/login`);
                }}
              >
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link href="/app/login">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>

        {/* <Row>
          <span>{message}</span>
          <nav>
            <Link to="/app/">Main</Link>
            {isLoggedIn && user.app_metadata.roles.includes("Partner") && (
              <Link to="/app/partner">Partner</Link>
            )}
            {isLoggedIn && user.app_metadata.roles.includes("Investor") && (
              <Link to="/app/investor">Investor</Link>
            )}

            {isLoggedIn ? (
              <a
                href="/"
                onClick={async (event) => {
                  event.preventDefault();
                  await logoutUser();
                  navigate(`/app/login`);
                }}
              >
                Logout
              </a>
            ) : (
              <Link to="/app/login">Login</Link>
            )}
          </nav>
        </Row> */}
      </Container>
    </Navbar>
  );
};

export default NavBar;
