import React from "react";
import { navigate } from "gatsby";
import { Container, Row, Button } from "react-bootstrap";
import { IdentityModal, useIdentityContext } from "react-netlify-identity-widget";
import "react-netlify-identity-widget/styles.css"; // delete if you want to bring your own CSS

function Login() {
  const identity = useIdentityContext();
  const [dialog, setDialog] = React.useState(false);
  return (
    <Container
      style={{
        marginTop: "30px",
        padding: "1rem",
        width: "500px",
        margin: "auto",
      }}
    >
      <h1>Log in</h1>
      <Button variant="primary" onClick={() => setDialog(true)}>
        {" "}
        log in
      </Button>

      <IdentityModal
        showDialog={dialog}
        onCloseDialog={() => setDialog(false)}
        onLogin={(user) => navigate("/app")}
        onSignup={(user) => navigate("/app")}
      />
    </Container>
  );
}

export default Login;
